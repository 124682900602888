import styled from 'styled-components';
import { em } from 'src/lib/units';

const ContentBlock = styled.div`
    padding: ${em(100)}rem ${em(200)}rem;
    text-align: ${props => props.center ? 'center' : null};
`;

const TextBlock = styled.div`
    width: 84%;  
`;

export { ContentBlock, TextBlock };