import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
`;

export const Screen = styled.div`
    background-color: #282B30;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s ease 0s;

    &:hover {
        transition: opacity .8s cubic-bezier(.2,1,.2,1);
        -webkit-transition: opacity .8s cubic-bezier(.2,1,.2,1);
        backface-visibility: hidden;
        transform: translateZ(0);
        opacity: 0.45;
    }
`;

export const Info = styled.div`
    bottom: 12px;
    position: absolute;
    left: 12px;
    z-index: 100;
`;

export const HeadingContainer = styled.div`
    transform: translateY(10px);
    opacity: 0;
    transition: opacity .95s cubic-bezier(.2,1,.2,1),transform .95s cubic-bezier(.2,1,.2,1);
    ${Container}:hover & {
        transform: translateY(0);
        opacity: 1;
    }
`;

export const TextContainer = styled.div`
    transform: translateY(20px);
    opacity: 0;
    transition: opacity .95s cubic-bezier(.2,1,.2,1),transform .95s cubic-bezier(.2,1,.2,1);
    ${Container}:hover & {
        transform: translateY(0);
        opacity: 1;
    }
`;