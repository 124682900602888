import React from 'react';
import rehypeReact from "rehype-react";
import { Heading, Text } from 'components/common/typography';
import RollingHeading1 from '../components/common/text/rolling-text';
import { TextBlock } from '../components/common/layout/blocks';
import { UL, LI } from 'components/common/list';
import { Grid } from 'components/pages/services/grids';
import Image, { PostImage } from 'components/common/image';
import { Link } from 'components/common/link';
import { HeaderPrimary, SectionIntro, SectionText } from 'components/pages/services/modules';

export const renderAst = new rehypeReact({
    createElement: React.createElement,
    components: {
        // "page-section": Section,
        "header-primary": HeaderPrimary,
        "section-intro": SectionIntro,
        'section-text': SectionText,
        "heading": Heading,
        "text": Text,
        "pic": Image,
        "post-image": PostImage,
        "rolling-h1": RollingHeading1,
        "text-block": TextBlock,
        "grid": Grid,
        "list": UL,
        "list-item": LI,
        "link": Link
    }
}).Compiler