import styled from 'styled-components';
import { minWidth } from 'lib/breakpoints';
import { rem } from 'lib/units';

export const HeaderPrimary = styled.div`
    padding-bottom: 36px;
    padding-top: 36px;
    @media (min-width: ${minWidth('lg')}) {
        padding-left: 100px;
        padding-right: 100px;
    }
`;

export const Section = styled.div`
    padding-bottom: 36px;
`;

export const SectionText = styled.div`
    padding-bottom: 36px;
    // padding-top: 36px;
    // padding-top: ${({ spacer }) => spacer === 'true' ? '100px' : '36px'};
    @media (min-width: ${minWidth('lg')}) {
        padding-left: 100px;
        padding-right: 100px;
    }
`;

export const SectionIntro = styled.div`
    margin-top ${rem(24)};
`;
