import styled from 'styled-components';
import { Text } from 'components/common/typography';
import { rem } from 'lib/units';

export const UL = styled.ul`
    margin-bottom: ${rem(18)};
`;

export const LI = styled(Text).attrs({ as: 'li' })`
    list-style-type: disc;
    margin: 0 0 0 ${rem(16)};
`;