import React, { Component } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import styled, { css } from 'styled-components';


const RollingDiv = styled.div`
    overflow: hidden;
    // border: 1px solid yellow;
    > * {
        color: red;
    }
`;

const RollingDivInner = styled.div`
    transform: translateY(-100%);
    transition: transform 1s ease-in-out;
    will-change: transform;
    ${props => props.isVisible && css`
        transform: translateY(0);
    `}
`;



// This function takes a component...
function withRollingText(WrappedComponent) {
    // ...and returns another component...
    return class extends Component {
        constructor(props) {
            super(props);
            // this.handleChange = this.handle
            // this.state = {
            //     data: selectData(DataSource, props)
        // };
            this.state = { isVisible: false };
        }

        componentDidMount() {
            // ... that takes care of the subscription...
            // DataSource.addChangeListener(this.handleChange);
        }

        componentWillUnmount() {
            // DataSource.removeChangeListener(this.handleChange);
        }

        // handleChange() {
        //     this.setState({
        //         data: selectData(DataSource, this.props)
        //     });
        // }

        onChange = (isVisible) => {
            this.setState({ isVisible });
        }

        render() {
            // ... and renders the wrapped component with the fresh data!
            // Notice that we pass through any additional props
            // return <WrappedComponent data={this.state.data} {...this.props} />;
            return (
                <VisibilitySensor onChange={this.onChange}>
                    <RollingDiv>
                        <RollingDivInner isVisible={this.state.isVisible}>
                            <WrappedComponent {...this.props} />
                        </RollingDivInner>
                    </RollingDiv>
                </VisibilitySensor>
            );
        }
    };
}

export default withRollingText;