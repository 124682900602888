import React from 'react';
import { Img } from './styled';

const Index = ({ src }) => <Img src={src} />;

export const PostImage = (props) => {
    // const responsiveImage = JSON.parse(fluidimage)
    // console.log('responsiveImage', responsiveImage);
    return <span>Add img here</span>;
}

export default Index;
