import styled from 'styled-components';

const getColStyles = () => {
    return `
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 6px;
    `;
}

export const Grid = styled.div`
    display: grid;
    ${getColStyles}
    img {
        width: 100%;
    }
`;