import React from 'react';
import { Container, Screen, Info, HeadingContainer, TextContainer } from './styled';
import Img from "gatsby-image";
import { Heading, Text } from 'components/common/typography';

const Card = ({ src, title, manufacturer }) => {
    return (
        <Container>
            <Img fluid={src} alt={`${title} by ${manufacturer}`} />
            <Screen />
            <Info>
                <HeadingContainer>
                    <Heading as="h2" appearance="paragon" marginBottom="0" light>{title}</Heading>
                </HeadingContainer>
                {manufacturer && (
                    <TextContainer>
                        <Text opacity=".8" margin="0" light>{`By ${manufacturer}`}</Text>
                    </TextContainer>
                )}
            </Info>
        </Container>
    );
};

export default Card;