import styled from 'styled-components';

export const Container = styled.div`
    ${({ appearance }) => appearance === 'bleed' && `
        margin-left: -200px;
        margin-right: -200px;
        
    `}

    ${({ appearance }) => appearance === 'fullBleed' && `
        width: 100vw;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
    `}

    ${({ appearance }) => appearance === 'standard' && `
        height: auto;
        width: 100%;
    `}
`;

// TODO responsive
export const Img = styled.img`
    height: auto;
    width: 100%;
`;

Img.defaultProps = {
    appearance: 'standard',
};

// export const Image = styled.span`border: 1px dotted red`;